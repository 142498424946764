import { render, staticRenderFns } from "./Freshness.vue?vue&type=template&id=63633c6e&scoped=true&"
import script from "./Freshness.vue?vue&type=script&lang=js&"
export * from "./Freshness.vue?vue&type=script&lang=js&"
import style0 from "./Freshness.vue?vue&type=style&index=0&id=63633c6e&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63633c6e",
  null
  
)

export default component.exports