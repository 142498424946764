<template>
  <div id="Change">
    <div class="container" id="change-container">
      <h3>
        Zamień swoją kwiaciarnię w profesjonalny raj dla roślin, z altaną to
        łatwe:
      </h3>
      <div id="change-items-container">
        <ChangeItem
          v-for="item in changeItems"
          :key="item.color"
          v-bind:itemData="item"
        />
      </div>
      <div class="button-container">
        <a href="https://rapa.pl/pl/model/sch-ak" target="_blank"
          ><button class="main-button" id="change-button">
            sprawdź wyposażenie standardowe
          </button></a
        >
      </div>
    </div>
  </div>
</template>

<script>
  /* eslint-disable */
  import ChangeItem from './ChangeItem.vue';
  import change1 from '../assets/altana_chlodnicza_na_kwiaty_w_6_rozmiarach.png';
  import change2 from '../assets/hartowane_szyby.png';
  import change3 from '../assets/regulowane_nogi.png';
  import change4 from '../assets/stal_nierdzewna.png';
  import change5 from '../assets/regulator_temperatury.png';
  import change6 from '../assets/chlodzenie_statyczne.png';
  import change7 from '../assets/rozsuwane_drzwi_z_blokada.png';
  import change8 from '../assets/motyw_kwiatowy.png';

  export default {
    data() {
      return {
        changeItems: [
          {
            color: change1,
            text1:
              'zyskasz dodatkową przestrzeń, bo przygotowaliśmy altanę w aż ',
            text2: '6 różnych wymiarach',
            mobile: '6 różnych wymiarów'
          },
          {
            color: change2,
            text1: 'pokażesz piękno swoich kompozycji dzięki ',
            text2: 'panoramicznym, hartowanym szybom, ',
            text3: 'które zapewniają doskonały widok z każdej strony',
            mobile: 'panoramiczne, hartowane szyby'
          },
          {
            color: change3,
            text1:
              'bez trudu zamontujesz altanę nawet w pomieszczeniu z nierówną podłogą, bo zastosowaliśmy w niej ',
            text2: 'regulowane nogi ze stali nierdzewnej',
            mobile: 'regulowane nogi ze stali nierdzewnej'
          },
          {
            color: change4,
            text1: 'łatwo utrzymasz czystość, bo urządzenie robiliśmy z ',
            text2: 'wysokiej jakości stali nierdzewnej ',
            text3: 'o fakturze „płótna”',
            mobile:
              'wnętrze z wysokiej jakości stali nierdzewnej o fakturze "płótna'
          },
          {
            color: change5,
            text1:
              'z łatwością dobierzesz odpowiednią temperaturę, bo zastosowaliśmy w altanie ',
            text2: 'elektroniczny regulator ',
            text3: 'z wyraźnym wyświetlaczem',
            mobile: 'elektroniczny regulator temperatury'
          },
          {
            color: change6,
            text1: 'zadbasz o środowisko, bo wnętrze altany chłodzi ',
            text2: 'ekologiczny czynnik R-290',
            mobile: 'ekologiczny czynnik chłodniczy R-290'
          },
          {
            color: change7,
            text1: 'bez trudu wstawisz do altany rośliny, bo zamontowaliśmy ',
            text2: 'rozsuwane drzwi, ',
            text3: 'które możesz zablokować na czas załadunku',
            mobile: 'rozsuwane drzwi'
          },
          {
            color: change8,
            text1:
              'podkreślisz swój profesjonalizm, bo szafa ozdobiona jest eleganckim motywem kwiatowym',
            mobile: 'elegancki motyw kwiatowy'
          }
        ]
      };
    },
    components: {
      ChangeItem
    }
  };
</script>

<style lang="sass" scoped>
  #Change
    width: 100vw
    #change-container
      margin: auto
      color: #616161
      h3
        font-weight: 400
        text-align: center
        padding-top: 90px
        margin: 0px 75px 75px
      #change-items-container
        margin: auto
        display: flex
        flex-wrap: wrap
        justify-content: space-between
    .button-container
      display: flex
      justify-content: center
      #change-button
        background-color: #5A783D
        margin: auto
        margin-bottom: 90px
        border: 1px solid #5A783D
        &:hover
          color: #5A783D
          background-color: #ffffff
  @media(max-width: 1050px)
    #Change
      width: 100vw
      #change-container
        margin: auto
        color: #616161
        width: 90vw
        h3
          font-size: 30px
          font-weight: 400
          text-align: center
          padding-top: 60px
          margin: 0px 75px 60px
        #change-items-container
          margin: auto
          display: grid
          grid-template-columns: 1fr 1fr 1fr
          justify-items: center
          flex-wrap: wrap
          justify-content: flex-start
          max-width: 860px
      .button-container
        display: flex
        justify-content: center
        #change-button
          background-color: #5A783D
          margin: auto
          margin-bottom: 60px
          border: 1px solid #5A783D
          &:hover
            color: #5A783D
            background-color: #ffffff
  @media(max-width: 700px)
    #Change
      #change-container
        h3
          font-size: 25px
          padding: 30px 25px
          margin: 0px 0px 30px
        #change-items-container
          grid-template-columns: 1fr 1fr
      .button-container
        #change-button
          &:hover
            color: #ffffff
            background-color: #5A783D
  @media(max-width: 500px)
    #Change
      #change-container
        h3
          margin: 0px 0px 30px
          padding: 30px 25px
          font-size: 25px
        #change-items-container
          justify-content: center
      .button-container
        #change-button
          margin: 0 auto 45px
          font-size: 12px
</style>
