<template>
  <div id="navigation">
    <div class="nav-list-container" v-bind:class="{ sticky: stickyNav }">
      <!-- v-bind:class="{ active: isActive }" -->
      <!-- <div v-if="logoVisible" class="header-logo-container"> -->
      <div class="header-logo-container">
        <a href="#Header"><img src="../assets/rapa.png" alt=""/></a>
      </div>
      <ul v-if="!mobileView && !tabletView">
        <a href="#change-container"><li>altana kwiatowa</li></a>
        <a href="#Freshness"><li>eksperyment</li></a>
        <a href="#Choose"><li>cennik</li></a>
        <a href="#FAQ"><li>o nas</li></a>
        <a href="#faq-questions-container"><li>faq</li></a>
        <a href="#Footer"><li>kontakt</li></a>
      </ul>
      <ul class="mobile-nav-list" v-if="mobileView || tabletView">
        <li v-on:click="toggleMenu()">menu</li>
      </ul>
      <div v-show="mobileMenuVisible" class="mobile-menu-container">
        <ul v-show="mobileView || tabletView" class="mobile-menu">
          <a v-on:click="showMenu" href="#change-container"
            ><li>altana kwiatowa</li></a
          >
          <a v-on:click="showMenu" href="#Freshness"><li>eksperyment</li></a>
          <a v-on:click="showMenu" href="#Choose"><li>cennik</li></a>
          <a v-on:click="showMenu" href="#FAQ"><li>o nas</li></a>
          <a v-on:click="showMenu" href="#faq-questions-container"
            ><li>faq</li></a
          >
          <a v-on:click="showMenu" href="#Footer"><li>kontakt</li></a>
          <!-- <li class="mobile-language-choice">
            <div><span>PL</span></div>
            <div><span>EN</span></div>
            <div><span>DE</span></div>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  /* eslint-disable */
  export default {
    data() {
      return {
        mobileListItems: document.querySelectorAll('.mobile-menu a')
      };
    },
    computed: {
      mobileView() {
        return this.$store.state.mobileView;
      },
      tabletView() {
        return this.$store.state.tabletView;
      },
      mobileMenuVisible() {
        return this.$store.state.mobileMenuVisible;
      },
      // logoVisible() {
      //   return this.$store.state.logoVisible;
      // },
      stickyNav() {
        return this.$store.state.stickyNav;
      }
    },
    methods: {
      showMenu() {
        this.$store.commit('showMenu');
      },
      escapeMenu() {
        this.$store.commit('escapeMenu');
      },
      toggleMenu() {
        let list = document.querySelector('.mobile-menu');
        if (this.mobileMenuVisible === false) {
          this.showMenu();
          setTimeout(() => {
            list.style.height = '275px';
            // this.addHeight(true);
            this.showList(true);
          }, 10);
        } else {
          this.showList();
          setTimeout(() => (list.style.height = '0px'), 300);
          setTimeout(this.showMenu, 600);
        }
      },
      escapeMenuList(e) {
        if (e.keyCode === 27 && this.mobileMenuVisible === true) {
          this.escapeMenu;
        }
      },
      showList(bool) {
        let list = document.querySelector('.mobile-menu');
        if (bool === true) {
          setTimeout(() => (list.style.opacity = '1'), 300);
        } else {
          list.style.opacity = '0';
        }
      }
    },
    created() {
      window.addEventListener('keyup', this.escapeMenu);
    },
    destroyed() {
      window.removeEventListener('keyup', this.escapeMenu);
    }
  };
</script>

<style lang="sass" scoped>
  #navigation
    position: relative
    width: auto
    display: flex
    justify-content: flex-end
    min-height: 85px
    .nav-list-container
      display: flex
      justify-content: space-between
      padding-right: 45px
      // border: 2px solid yellow
      width: 100vw
      transition: 0.3s
      // border: 2px solid yellow
      .header-logo-container
        // border: 2px solid green
        margin: 35px 0 0 calc((100vw - 1224px)/2)
        // margin: 35px 0 0 50px
        transition: 0.3s
        // border: 2px solid red
      ul
        display: flex
        justify-content: center
        margin-top: 45px
        margin-left: 50px
        list-style: none
        font-size: 16px
        font-weight: bold
        transition: 0.3s
        // border: 2px solid yellow
        li
          text-transform: uppercase
          margin: 0 20px
          letter-spacing: 1px
          cursor: pointer
        .mobile-nav-list
          padding: 10px
          margin-top: 35px
      .mobile-menu-container
        // height: 325px
        padding: 0
        position: absolute
        left: 0
        bottom: 0px
        transform: translateY(100%)
        background: rgba(black, 0.8)
        width: 100vw
        z-index: 4
        .mobile-menu
          height: 0px
          // overflow: hidden
          width: 90%
          margin: 0px auto
          padding: 0
          display: flex
          flex-direction: column
          text-align: center
          transition: 0.3s
          opacity: 0
          li
            font-size: 12px
            width: 100%
            margin: 0
            padding: 14px 0
            border-top: 0.1px solid #444444
            transition: 0.3s
          // .mobile-language-choice
          //   display: flex
          //   justify-content: space-around
          //   align-items: center
          //   padding: 5px 0
          //   div
          //     height: 40px
          //     flex-grow: 1
          //     // border: 2px solid red
          //     display: grid
          //     span
          //       margin: auto
          //   div:nth-child(2)
          //     border-left: 0.1px solid #444444
          //     border-right: 0.1px solid #444444
    .sticky
        position: fixed
        top: 0
        left: 0
        background: rgba(black, 0.8)
        z-index: 100
        .header-logo-container
          // margin: 15px 0 15px 50px
          margin: 15px 0 15px calc((100vw - 1224px)/2)
        ul
          margin-top: 30px
  @media(max-width: 1050px)
      #navigation
        .nav-list-container
          .header-logo-container
            margin: 35px 0 0 calc(50px)
            transition: 0.3s
          ul
            margin-left: 30px
            li
              margin: 0 12px
        .sticky
          position: fixed
          top: 0
          left: 0
          background: rgba(black, 0.8)
          z-index: 100
          .header-logo-container
            margin: 15px 0 10px calc(50px)
          ul
            margin-top: 30px
</style>
