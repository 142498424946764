<template>
  <div id="Freshness">
    <div class="container">
      <h3>
        Utrzymaj świeżość roślin aż do <span>18 dni</span>
        <br v-if="mobileView" />
        dzięki altanie chłodniczej
      </h3>
      <p>
        Sprawdziliśmy to. 3 gatunki kwiatów podzieliliśmy na 2 grupy. Pierwszą
        wstawiliśmy do naszej altany chłodniczej. Drugą trzymaliśmy w
        temperaturze pokojowej. Rośliny, które były przechowywane w altanie,
        wyglądały świeżo i zdrowo nawet 18 dni po zakupie.*
      </p>
      <p>
        Na zdjęciach pokazaliśmy, jak zmieniały się kwiaty w altanie w trakcie
        testu. Udało się przedłużyć ich życie nawet 6 razy w porównaniu do
        kwiatów trzymanych poza altaną, które po 5 dniach zwiędły i nie nadawały
        się do ekspozycji i sprzedaży. Rezultaty będą wahały się w zależności od
        gatunku.
      </p>
      <div id="freshness-image-container">
        <img src="@/assets/eksperyment.png" alt="eksperyment przedłużenia świeżości kwiatów" />
      </div>
      <p id="star-info">
        *Kwiaty poddane doświadczeniu pochodziły od tego samego dostawcy, z
        jednego opakowania zbiorczego. Woda w zbiornikach była wymieniana
        regularnie i w tej samej częstotliwości dla każdej grupy.
      </p>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
  export default {
    computed: {
      mobileView() {
        return this.$store.state.mobileView;
      }
    }
  };
</script>

<style lang="sass" scoped>
  #Freshness
    background-color: #F9F9F9
    .container
      margin: auto
      padding-bottom: 90px
      display: flex
      flex-direction: column
      align-items: center
      h3
        width: 800px
        text-align: center
        margin: 90px auto 90px
      span
        font-weight: 700
        font-size: 60px
      p
        font-weight: 400
        color: #616161
        margin-bottom: 45px
      #freshness-image-container
        margin-top: 30px
        margin-bottom: 75px
        width: 1224px
        display: flex
        justify-content: center
        img
          width: 761.5px
      #star-info
        width: 1224px
        font-size: 12px
        margin-bottom: 0px
  @media(max-width: 1050px)
    #Freshness
      .container
        padding-bottom: 60px
        width: 90vw
        h3
          font-size: 30px
          width: 600px
          margin: 60px auto 45px
        span
          font-size: 36px
        p
          margin-bottom: 30px
        #freshness-image-container
          margin-bottom: 45px
          width: 90vw
          max-width: 1224px
          img
            width: 100%
        #star-info
          width: 90vw
  @media(max-width: 700px)
    #Freshness
      .container
        h3
          font-size: 26px
          width: 85%
        span
          font-size: 30px
        p
          width: 80%
          margin-bottom: 20px
        #freshness-image-container
          img
            width: 85vw
        #star-info
          width: 80vw
</style>
