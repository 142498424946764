<template>
  <div id="Choose">
    <h3>Wybierz altanę skrojoną na Twoje potrzeby</h3>
    <div class="container" id="types-container">
      <div class="type" id="standard">
        <div>
          <div class="choose-image">
            <img
              class="main-photo"
              src="../assets/standard.png"
              alt="altana standard sch-ak 1205"
            />
          </div>
          <h4>standard</h4>
          <ul>
            <li v-for="item in standard" v-bind:key="item">
              <img v-bind:src="flake" alt="" />{{ item }}
            </li>
          </ul>
        </div>
        <div>
          <a
            href="mailto:rapa@rapa.lublin.pl?subject=Zapytanie o altanę kwiatową SCh-AK 1205"
            ><button class="main-button type-button">
              zapytaj o produkt
            </button></a
          >
        </div>
      </div>
      <div class="type" id="standard-plus">
        <div>
          <img
            class="main-photo"
            src="../assets/standard-plus.png"
            alt="altana standard sch-ak 1605"
          />
          <h4>standard-pro</h4>
          <ul>
            <li v-for="item in standardPlus" v-bind:key="item">
              <img v-bind:src="flake" alt="" />{{ item }}
            </li>
          </ul>
        </div>
        <div>
          <a
            href="mailto:rapa@rapa.lublin.pl?subject=Zapytanie o altanę kwiatową SCh-AK 1605"
            ><button class="main-button type-button">
              zapytaj o produkt
            </button></a
          >
        </div>
      </div>
      <div class="type" id="premium">
        <div>
          <img
            class="main-photo"
            src="../assets/premium.png"
            alt="altana standard sch-ak 2205"
          />
          <h4>premium</h4>
          <ul>
            <li v-for="item in premium" v-bind:key="item">
              <img v-bind:src="flake" alt="" />{{ item }}
            </li>
          </ul>
        </div>
        <div>
          <a
            href="mailto:rapa@rapa.lublin.pl?subject=Zapytanie o altanę kwiatową SCh-AK 2205"
            ><button class="main-button type-button">
              zapytaj o produkt
            </button></a
          >
        </div>
      </div>
    </div>
    <a href="https://rapa.pl/pl/konfigurator/29" target="_blank"
      ><button class="main-button choose-button">
        skonfiguruj swoją altanę na stronie producenta
      </button></a
    >
  </div>
</template>

<script>
  /* eslint-disable */
  import flake from '../assets/flake.png';

  export default {
    data() {
      return {
        standard: [
          'szerokość: 1205 mm',
          '3 klasa klimatyczna',
          'odszranianie automatyczne',
          '2 podesty pod kwiaty'
        ],
        standardPlus: [
          'szerokość: 1605 mm',
          '3 klasa klimatyczna',
          'odszranianie automatyczne',
          '2 podesty pod kwiaty',
          '1 półka wisząca regulowana',
          'zamek do drzwi'
        ],
        premium: [
          'szerokość: 2205 mm',
          '3 klasa klimatyczna',
          'odszranianie automatyczne',
          '2 podesty pod kwiaty',
          '2 półki wiszące regulowane',
          'zamek do drzwi',
          'filtr przeciwpyłkowy'
        ],
        flake
      };
    }
  };
</script>

<style lang="sass" scoped>
  #Choose
    // height: 1310px
    background-image: url('../assets/choose.jpg')
    background-position: 20% 8%
    background-size: 2100px auto
    text-align: center
    h3
      color: #ffffff
      width: 100vw
      text-align: center
      padding-top: 90px
    .choose-button
      color: #AE2A36
      background-color: #fff
      margin-top: 65px
      margin-bottom: 120px
      font-weight: 900
      transition: 0.3s
      border: 1px solid #fff
      &:hover
        color: #ffffff
        background-color: #AE2A36
    #types-container
      height: 900px
      display: flex
      margin: auto
      justify-content: space-between
      align-items: center
      .type
        position: relative
        width: 330px
        // height: 702px
        background-color: #ffffff
        border-radius: 20px
        padding: 15px 20px
        display: flex
        flex-direction: column
        align-items: center
        color: #616161
        transition: 0.3s
        overflow: hidden
        .main-photo
          height: 200px
          transition: 0.3s
        h4
          text-transform: uppercase
          font-size: 30px
          transition: 0.3s
        div
          transition: 0.3s
          .prize
            color: #145EB2
            margin-top: 25px
          .type-button
            background-color: #AE2A36
            margin: 30px 0 60px 0
            font-size: 16px
            transition: 0.3s
        ul
          list-style: none
          margin: 20px auto 0px
          width: 300px
          height: 220px
          li
            margin: 10px 0
            display: flex
            font-weight: 400
            padding: 0 20px
            img
              width: 20px
              height: 20px
              margin-right: 20px
        &:hover
          width: 430px
          // height: 800px
          .main-photo
            height: 240px
          h4
            text-transform: uppercase
            font-size: 35px
          ul
            list-style: none
            width: 300px
            li
              img
                width: auto
  @media(max-width: 1050px)
    #Choose
      height: auto
      h3
        font-size: 30px
        padding-top: 60px
      .choose-button
        margin-top: 0px
        margin-bottom: 60px
      #types-container
        height: auto
        width: 90vw
        margin: 60px auto
        .type
          width: 33%
          // height: 710px
          margin: 0 10px
          h4
            font-size: 24px
          div
            .prize
              margin-top: 60px
            .type-button
              margin: 45px 0 15px
              line-height: 20px
          ul
            li
              font-size: 14px
              width: 90%
              text-align: left
              img
                margin-right: 15px
                margin-left: 40px
          &:hover
            width: 33%
            // height: 710px
            .main-photo
              height: 200px
            h4
              font-size: 24px
            ul
              li
                img
                  width: 20px
  @media(max-width: 700px)
    #Choose
      h3
        margin: auto
        font-size: 26px
        width: 85vw
        padding-top: 45px
      .choose-button
        // font-size: 14px
        margin: 45px auto
        // padding: 20px 20px
        // font-weight: 600
        &:hover
          color: #AE2A36
          background-color: #fff
      #types-container
        flex-direction: column
        .type
          width: 80vw
          max-width: 300px
          // height: auto
          margin: 45px 0 0
          div
            .prize
              margin-top: 35px
            .type-button
              font-size: 15px
              font-weight: 400
              padding: 18px 35px
              margin-bottom: 20px
          ul
            height: auto
            li
              img
                margin: 0 10px
          &:hover
            width: 80vw
            height: auto
  @media(max-width: 500px)
    #Choose
      h3
      .choose-button
        font-size: 12px
</style>
