<template>
  <div class="ChangeItem">
    <div
      class="change-photo-container"
      v-bind:style="{ backgroundImage: `url(${itemData.color})` }"
    >
    </div>
    <div class="change-text-container">
      <p v-if="!mobileView">
        {{ itemData.text1 }}<b>{{ itemData.text2 }}</b
        >{{ itemData.text3 }}
      </p>
      <p v-if="mobileView">
        {{ itemData.mobile }}
      </p>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
  export default {
    props: {
      itemData: Object
    },
    computed: {
      mobileView() {
        return this.$store.state.mobileView;
      }
    }
  };
</script>

<style lang="sass" scoped>
  .ChangeItem
    width: 282px
    margin-bottom: 90px
    .change-photo-container
      width: 282px
      height: 282px
      overflow: hidden
      border-radius: 20px
      box-shadow: 6px 6px 12px rgba(black, 0.16)
      background-size: 400px 400px
      background-position: 20% 20%
    .change-text-container
      p
        font-weight: 400
        margin-top: 40px
  @media(max-width: 1050px)
    .ChangeItem
      width: 218px
      margin-bottom: 60px
      .change-photo-container
        width: 218px
        height: 215px
        background-size: 300px 300px
      .change-text-container
        p
          font-weight: 400
          margin-top: 30px
  @media(max-width: 700px)
    .ChangeItem
      width: 35vw
      margin: 0 15px 45px 15px
      .change-photo-container
        width: 130px
        height: 130px
        background-size: 120% 120%
      .change-text-container
        p
          margin-top: 20px
</style>
