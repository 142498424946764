<template>
  <div id="FAQ">
    <div class="container" id="faq-container">
      <div id="contact">
        <div v-if="!mobileView" id="contact-photo">
          <img src="../assets/girl3.png" alt="profesjonalna kwiaciarnia" />
        </div>
        <div id="contact-text">
          <h3>Zaprojektowaliśmy altanę z{{"\xa0"}}myślą o Twoim biznesie</h3>
          <p>
            Zajmujemy się produkcją urządzeń chłodniczych od 25 lat. Altanę
            chłodniczą na kwiaty zaprojektowaliśmy specjalnie z{{'\xa0'}}myślą o{{'\xa0'}}florystach, profesjonalistach takich, jak Ty.
          </p>
          <p>
            Specjalizujemy się w produkcji urządzeń chłodniczych dla sklepów i{{'\xa0'}}gastronomii. Altana na kwiaty jest dla nas produktem szczególnym.
            Dlaczego?
          </p>
          <p>
            Pewnego dnia zgłosiła się do nas florystka, taka jak Ty.
            Powiedziała, że poszukuje rozwiązania swojego największego problemu:
            tego, że kwiaty szybko więdną, a{{'\xa0'}}to generuje ogromne straty każdego
            dnia. W{{'\xa0'}}odróżnieniu od innych, nie oferowaliśmy jej chłodni, którą
            mogłaby postawić na magazynie.
            <span
              >Zbudowaliśmy altanę, która jednocześnie wydłuża świeżość kwiatów
              i{{'\xa0'}}umożliwia ich stałą ekspozycję.</span
            >
          </p>
          <p>
            Teraz taką altanę może mieć każdy sprzedawca kwiatów. I{{'\xa0'}}jesteśmy z{{'\xa0'}}tego dumni.
          </p>
          <div class="button-container">
          <a href="https://rapa.pl/pl/kontakt" target="_blank">
            <button class="main-button">skontaktuj się z nami</button>
          </a>
          </div>
        </div>
      </div>
      <div id="faq-questions-container">
        <div id="faq-questions">
          <div id="faq-text">
            <h3>FAQ</h3>
            <ul>
              <li>
                <div v-on:click="showAnswer" class="question">
                  <p>Gdzie kupię altanę <span class="question-span">kwiatową?<img class="question-mobile-arrow" v-show="mobileFaq" src="../assets/Polygon 1.png" alt=""/></span></p>
                  <img class="question-desktop-arrow" v-show="!mobileFaq" src="../assets/Polygon 1.png" alt=""/>
                </div>
                <div data-height="185px" data-mobile="300px" class="answer">
                  <p>
                    Altanę kwiatową kupisz bezpośrednio u producenta, firmy
                    RAPA. Możesz przejść bezpośrednio na stronę
                    <b>rapa.pl/pl/model/sch-ak.</b> Nasze urządzenia znajdziesz
                    również u dystrybutorów urządzeń chłodniczych. Po więcej
                    informacji zapraszamy do kontaktu z naszym Działem Obsługi
                    Klienta: <br /><br />tel. +48 81 742 53 10-14
                    <a href="mailto:rapa@rapa.lublin.pl">rapa@rapa.lublin.pl</a>
                  </p>
                </div>
              </li>
              <li>
                <div v-on:click="showAnswer" class="question">
                  <p>Jakie wymiary ma <span class="question-span">altana?<img class="question-mobile-arrow" v-show="mobileFaq" src="../assets/Polygon 1.png" alt=""/></span></p>
                  <img class="question-desktop-arrow" v-show="!mobileFaq" src="../assets/Polygon 1.png" alt=""/>
                </div>
                <div data-height="515px" data-mobile="530px" class="answer">
                  <p>
                    Zaprojektowaliśmy altanę w 6 różnych wymiarach. Dzięki temu
                    możesz dopasować ją do każdego wnętrza. Dostępne szerokości
                    sprawdzisz w tabeli:
                  </p>
                  <table>
                    <thead>
                      <tr>
                        <th>model</th>
                        <th>
                          SCh-AK <br />
                          1205
                        </th>
                        <th>
                          SCh-AK <br />
                          1405
                        </th>
                        <th>
                          SCh-AK <br />
                          1605
                        </th>
                        <th>
                          SCh-AK <br />
                          1805
                        </th>
                        <th>
                          SCh-AK <br />
                          2005
                        </th>
                        <th>
                          SCh-AK <br />
                          2205
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>szerokość altany</th>
                        <td>120,5</td>
                        <td>140,5</td>
                        <td>160,5</td>
                        <td>180,5</td>
                        <td>200,5</td>
                        <td>220,5</td>
                      </tr>
                      <tr>
                        <th>
                          szerokość ekspozycji <br />
                          altany
                        </th>
                        <td>110</td>
                        <td>130</td>
                        <td>150</td>
                        <td>170</td>
                        <td>190</td>
                        <td>210</td>
                      </tr>
                      <tr>
                        <th>głębokość altany</th>
                        <td>74,5</td>
                        <td>74,5</td>
                        <td>74,5</td>
                        <td>74,5</td>
                        <td>74,5</td>
                        <td>74,5</td>
                      </tr>
                      <tr>
                        <th>
                          głębokość ekspozycji <br />
                          altany
                        </th>
                        <td>62</td>
                        <td>62</td>
                        <td>62</td>
                        <td>62</td>
                        <td>62</td>
                        <td>62</td>
                      </tr>
                      <tr>
                        <th>wysokość altany</th>
                        <td>199,5</td>
                        <td>199,5</td>
                        <td>199,5</td>
                        <td>199,5</td>
                        <td>199,5</td>
                        <td>199,5</td>
                      </tr>
                      <tr>
                        <th>
                          wysokość ekspozycji<br />
                          altany
                        </th>
                        <td>150</td>
                        <td>150</td>
                        <td>150</td>
                        <td>150</td>
                        <td>150</td>
                        <td>150</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </li>
              <li>
                <div v-on:click="showAnswer" class="question">
                  <p>Jakie jest wyposażenie standardowe altany <span class="question-span">chłodniczej?<img class="question-mobile-arrow" v-show="mobileFaq" src="../assets/Polygon 1.png" alt=""/></span></p>
                  <img class="question-desktop-arrow" v-show="!mobileFaq" src="../assets/Polygon 1.png" alt=""/>
                </div>
                <div data-height="510px" data-mobile="700px" class="answer">
                  <p>W wyposażeniu standardowym znajdziesz:</p>
                  <ul>
                    <li>
                      Agregat wewnętrzny zasilany ekologicznym czynnikiem R-290
                      (3 kl. klimatyczna)
                    </li>
                    <li>Chłodzenie grawitacyjne</li>
                    <li>Elektroniczny regulator temperatury z wyświetlaczem</li>
                    <li>System automatycznego odparowania skroplin</li>
                    <li>Korpus wykonany ze stali nierdzewnej „szlif"</li>
                    <li>Nogi regulowane ze stali nierdzewnej „szlif"</li>
                    <li>Ozdobny motyw kwiatowy na korpusie</li>
                    <li>
                      Dno komory przechowalniczej ze stali nierdzewnej „płótno”
                    </li>
                    <li>
                      Drzwi przeszklone, rozsuwane z blokadą do załadunku,
                      samodomykające
                    </li>
                    <li>Oświetlenie LED</li>
                    <li>
                      Podesty pod kwiaty (2 szt.) ze stali nierdzewnej „płótno”
                    </li>
                    <li>
                      Poprzeczka do zawieszania kompozycji z kwiatów ciętych
                    </li>
                    <li>Szyba frontowa panoramiczna</li>
                    <li>Urządzenie przeszklone z czterech stron</li>
                    <li>
                      Wszystkie szyby hartowane obustronnie, zespolone,
                      niskoemisyjne
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <div v-on:click="showAnswer" class="question">
                  <p>Co znajdę w wyposażeniu <span class="question-span">dodatkowym?<img class="question-mobile-arrow" v-show="mobileFaq" src="../assets/Polygon 1.png" alt=""/></span></p>
                  <img class="question-desktop-arrow" v-show="!mobileFaq" src="../assets/Polygon 1.png" alt=""/>
                </div>
                <div data-height="296px" data-mobile="360px" class="answer">
                  <p>Wśród dodatkowych opcji możesz wybrać:</p>
                  <ul>
                    <li>Zabezpieczenie parownika powłoką ochronną</li>
                    <li>Zamek do drzwi</li>
                    <li>Urządzenie bez agregatu</li>
                    <li>Filtr przeciwpyłkowy</li>
                    <li>
                      Półkę wiszącą regulowana ze stali nierdzewnej „płótno”
                    </li>
                    <li>Rejestrator temperatury USB – bezprzewodowy</li>
                  </ul>
                  <p>
                    Ponadto możesz zakupić przedłużenie gwarancji do
                    <b>4 lub 5 lat.</b>
                  </p>
                </div>
              </li>
              <li>
                <div v-on:click="showAnswer" class="question">
                  <p>Z czego wykonane jest <span class="question-span">urządzenie?<img class="question-mobile-arrow" v-show="mobileFaq" src="../assets/Polygon 1.png" alt=""/></span></p>
                  <img class="question-desktop-arrow" v-show="!mobileFaq" src="../assets/Polygon 1.png" alt=""/>
                </div>
                <div data-height="128px" data-mobile="200px" class="answer">
                  <p>
                    Altanę kwiatową wykonaliśmy ze stali nierdzewnej, którą
                    łatwo utrzymasz w czystości. Wszystkie szyby zastosowane w
                    urządzeniu są hartowane obustronnie, zespolone i
                    niskoemisyjne. Dzięki temu są bezpieczne dla Ciebie i Twoich
                    klientów.
                  </p>
                </div>
              </li>
              <li>
                <div v-on:click="showAnswer" class="question">
                  <p>Czy altana posiada <span class="question-span">oświetlenie?<img class="question-mobile-arrow" v-show="mobileFaq" src="../assets/Polygon 1.png" alt=""/></span></p>
                  <img class="question-desktop-arrow" v-show="!mobileFaq" src="../assets/Polygon 1.png" alt=""/>
                </div>
                <div data-height="106px" data-mobile="150px" class="answer">
                  <p>
                    Tak! W altanie zainstalowaliśmy energooszczędne oświetlenie
                    LED, które podświetli Twoje kompozycje ale też nie będzie
                    wpływało na świeżość kwiatów, ponieważ nie wydziela ciepła.
                  </p>
                </div>
              </li>
              <li>
                <div v-on:click="showAnswer" class="question">
                  <p>Jaki zakres temperatur mogę ustawić <span class="question-span">w altanie?<img class="question-mobile-arrow" v-show="mobileFaq" src="../assets/Polygon 1.png" alt=""/></span></p>
                  <img class="question-desktop-arrow" v-show="!mobileFaq" src="../assets/Polygon 1.png" alt=""/>
                </div>
                <div data-height="106px" data-mobile="150px" class="answer">
                  <p>
                    Zakres temperatur wewnątrz urządzenia wynosi +4°C ÷ +10°C
                    (przy temp. otoczenia +25°C i wilgotności wzgl. 60%). Zakres
                    temperatur otoczenia dla pracy urządzenia wynosi +16°C ÷
                    +32°C.
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div id="faq-photo">
            <img src="../assets/altana_symulacja.png" alt="Altana chłodnicza na kwiaty w kolorze czarnym" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
  export default {
    data() {
      return {
        answers: [],
        mobileArrows: [],
        desktopArrows: []
      };
    },
    methods: {
      showAnswer(e) {
        let answer, answerHeight, answerHeightMobile, desktopArrow, mobileArrow
        if(e.target.classList.value === 'question'){
          answer = e.target.parentElement.lastChild;
        } else if (e.target.classList.value === 'question-mobile-arrow'){
          answer = e.target.parentElement.parentElement.parentElement.parentElement.lastChild
        } else if (e.target.classList.value === 'question-span'){
          answer = (e.target.parentElement.parentElement.parentElement.lastChild)
        } else {
          answer = e.target.parentElement.parentElement.lastChild;
        }
          answerHeight = answer.parentElement.lastChild.dataset.height;
          answerHeightMobile = answer.parentElement.lastChild.dataset.mobile;
          desktopArrow = answer.parentElement.firstChild.lastChild;
          mobileArrow = answer.parentElement.firstChild.firstChild.lastChild.lastChild
        if (answer.style.height !== answerHeight && answer.style.height !== answerHeightMobile){
          this.answers.forEach(answer => {answer.style.height = '0px'})
          this.mobileArrows.forEach(arrow => {arrow.style.transform = 'rotate(0deg)'})
          this.desktopArrows.forEach(arrow => {arrow.style.transform = 'rotate(0deg)'})
          if(this.mobileView || this.mobileFaq){
            answer.style.height = answerHeightMobile
          } else {
            answer.style.height = answerHeight
          }
          desktopArrow.style.transform = "rotate(90deg)"
          mobileArrow.style.transform = "rotate(90deg)"
        } else {
          answer.style.height = '0px';
          desktopArrow.style.transform = "rotate(0deg)"
          mobileArrow.style.transform = "rotate(0deg)"
        }
      }
    },
    computed: {
      mobileView() {
        return this.$store.state.mobileView;
      },
      tabletView() {
        return this.$store.state.tabletView;
      },
      mobileFaq(){
        return this.$store.state.mobileFaq;
      }
    },
    mounted() {
      this.answers = document.querySelectorAll('.answer');
      this.mobileArrows = document.querySelectorAll('.question-mobile-arrow');
      this.desktopArrows = document.querySelectorAll('.question-desktop-arrow');
    }
  };
</script>

<style lang="sass" scoped>
  #FAQ
    padding-bottom: 90px
    background-color: #F9F9F9
    #faq-container
      width: 90vw
      margin: auto
      display: flex
      flex-direction: column
      #contact
        padding-top: 90px
        display: flex
        #contact-text
          margin-left: 30px
          max-width: calc(50% - 50px)
          h3
            margin: 0px 0 60px
          p
            margin: 25px 0
            font-weight: 400
            color: #616161
            span
              font-weight: 700
          .button-container
            width: 100vw
            button
              background: #5A783D
              margin-top: 35px
              border: 1px solid #5A783D
              &:hover
                color: #5A783D
                background-color: #ffffff
        #contact-photo
          margin-right: 30px
      #faq-questions
        padding-top: 60px
        display: flex
        margin-top: 60px
        #faq-text
          width: 50%
          margin-right: 0px
          h3
            font-size: 24px
            text-align: center
            padding-top: 45px
            padding-bottom: 25px
          ul
            list-style: none
            li
              font-size: 16px
              font-weight: 600
              color: #616161
              margin-top: 25px
              .question
                display: flex
                justify-content: space-between
                align-items: center
                cursor: pointer
                p
                  span
                    margin-left: 0px
                    white-space: nowrap
                    img
                      margin-left: 20px
                img
                  height: 10px
                  transition: 0.3s
                &:hover
                  color: #000
              .answer
                height: 0px
                transition: 0.3s
                font-size: 3
                overflow: hidden
                font-weight: 400
                p
                  font-size: 16px
                  padding: 10px 0px 10px
                ul
                  list-style: inside
                  padding: 10px 0 0
                  li
                    font-size: 16px
                    font-weight: 400
                    margin: 0px
                a
                  color: #616161
                  color: black
                  font-weight: 700
                  cursor: pointer
                table
                  font-size: 16px
                  width: 100%
                  font-weight: 400
                  td
                    border-bottom: 1px solid #616161
                    border-right: 1px solid #616161
                    text-align: center
                    height: 50px
                  thead
                    th
                      font-weight: 400
                      text-align: center
                      border-bottom: 1px solid #616161
                      border-right: 1px solid #616161
                  tbody
                    th
                      font-weight: 400
                      border-bottom: 1px solid #616161
                      border-right: 1px solid #616161
        #faq-photo
          margin-left: 30px
          width: 50%
          height: 589px !important
          background-image: url('../assets/altana_desktop.png')
          background-size: cover
          background-position: 40% center
          border-radius: 20px
          img
            display: none
            width: 575
            height: 589
  @media(max-width: 1050px)
    #FAQ
      padding-bottom: 60px
      #faq-container
        #contact
          padding-top: 60px
          width: 90vw
          #contact-text
            max-width: calc(45% - 50px)
            h3
              font-size: 30px
              margin: 0px 0 30px
            p
            .button-container
              button
                margin-top: 0px
          #contact-photo
            width: 50%
            height: auto
            background-image: url('../assets/girl3.png')
            background-size: cover
            background-position: 52% center
            border-radius: 20px
            img
              display: none
              width: 100%
        #faq-questions
          padding-top: 45px
          margin-top: 15px
          width: 90vw
          #faq-text
            h3
              text-align: left
          #faq-photo
            height: 621px
            background-image: url('../assets/altana_symulacja.png')
  @media(max-width: 700px)
     #FAQ
      padding-top: 0px
      padding-bottom: 45px
      width: 100vw
      #faq-container
        margin: 0
        width: 100vw
        #contact
          padding-top: 45px
          margin-bottom: 0px
          width: 100vw
          justify-content: center
          #contact-text
            margin-left: 0px
            max-width: 90vw
            h3
              font-size: 24px
              margin: 0px 0 45px
            p
              display: flex
              flex-wrap: wrap
              width: 90vw
            .button-container
              width: 100%
              text-align: center
              button
                background: #5A783D
                margin: 15px auto 0px
                &:hover
                  color: #ffffff
                  background-color: #5A783D
        #faq-questions
          padding-top: 0px
          flex-direction: column-reverse
          width: 100vw
          align-items: center
          #faq-text
            width: 80vw
            ul
              li
                .answer
                  a
                    font-weight: 400
                  table
                    font-size: 12px
          #faq-photo
            margin-top: 30px
            margin-left: 0px
            width: 90vw
            height: 105vw !important
            background-image: url('../assets/altana_mobile.png')
            img
              width: 100%
              height: auto
</style>
