<template>
  <div id="IsForYou">
    <h3>Altana chłodnicza jest dla Ciebie jeśli:</h3>
    <div class="container" id="ify-container">
      <div class="isforyou-item">
        <div class="text-container">
          <p>
            Chcesz przechowywać kompozycje w <span>odpowiedni sposób.</span> W
            altanie możesz zarówno postawić rośliny w zbiornikach, jak i
            powiesić gotowe bukiety na poprzeczce.
          </p>
        </div>
        <div class="number">01</div>
        <div class="brown-border"></div>
      </div>
      <div class="isforyou-item">
        <div class="text-container">
          <p>
            Chcesz zapewnić roślinom odpowiednie nawilżenie. W altanie
            zastosowaliśmy <span>chłodzenie grawitacyjne,</span> które nie
            wysusza powietrza.
          </p>
        </div>
        <div class="number">02</div>
        <div class="brown-border"></div>
      </div>
      <div class="isforyou-item">
        <div class="text-container">
          <p>
            Chcesz się wyróżnić. Podkreśl wszystkie kolory swoich kompozycji,
            dzięki <span>energooszczędnemu oświetleniu LED.</span>
          </p>
        </div>
        <div class="number">03</div>
        <div class="brown-border"></div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
  export default {};
</script>

<style lang="sass" scoped>
  #IsForYou
    min-height: 705px
    width: 100vw
    background-image: url("../assets/profesjonalny_bukiet.jpg")
    background-size: cover
    background-position: 0% 25%
    padding-top: 90px
    color: #616161
    h3
      text-align: center
      font-weight: 400
    #ify-container
      margin: 170px auto 0
      display: flex
      .isforyou-item
        margin: auto
        width: 310px
        height: 310px
        position: relative
        .text-container
          position: relative
          border-radius: 20px
          padding: 35px
          font-size: 20px
          font-weight: 400
          background-color: #ffffff
          z-index: 2
          width: 100%
          height: 100%
          p
            padding: 0px
            span
              font-weight: 700
        .number
          position: absolute
          font-family: 'Playfair Display', serif
          font-size: 113px
          font-style: italic
          top: -130px
          left: -30px
          color: rgba(#616161, 0.57)
          z-index: 3
        .brown-border
          position: absolute
          width: 250px
          height: 280px
          border: 4px solid #610000
          border-radius: 20px
          transform: rotate(-15deg)
          top: 0
          right: 0
          z-index: 1
  @media(max-width: 1050px)
    #IsForYou
      background-size: 200%
      background-position: 50% 20%
      padding-top: 60px
      h3
        font-size: 30px
      #ify-container
        width: 90vw
        margin: 120px auto 0
        justify-content: space-around
        flex-wrap: wrap
        .isforyou-item
          margin: 0 20px 90px
          width: 229px
          height: 229px
          .text-container
            padding: 20px
            font-size: 16px
          .number
            font-size: 83px
            top: -95px
          .brown-border
            width: 200px
            height: 210px
  @media(max-width: 700px)
    #IsForYou
      background-size: 500%
      background-position: 49% 4%
      padding-bottom: 45px
      h3
        width: 80vw
        margin: auto
      #ify-container
        margin: 0px auto 0
        flex-direction: column
        jusify-content: center
        .isforyou-item
          margin: auto
          width: 50vw
          height: 50vw
          margin-top: 120px
          .text-container
            display: flex
            justify-content: center
            align-items: center
            padding: 35px
            p
              padding: 0px 0px
              font-size: 16px
          .number
            font-size: 80px
            font-weight: 900
          .brown-border
            width: 40vw
            height: 45vw
  @media(max-width: 500px)
    #IsForYou
      h3
      #ify-container
        .isforyou-item
          width: 65vw
          height: 65vw
          .text-container
            padding: 15px
          .brown-border
            width: 55vw
            height: 60vw
</style>
