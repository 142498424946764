<template>
  <div id="App">
    <Policy />
    <Cookies />
    <Header />
    <Change />
    <IsForYou />
    <Freshness />
    <Choose />
    <FAQ />
    <Footer />
  </div>
</template>

<script>
  /* eslint-disable */
  import Header from './components/Header.vue';
  import Change from './components/Change.vue';
  import IsForYou from './components/IsForYou.vue';
  import Freshness from './components/Freshness.vue';
  import Choose from './components/Choose.vue';
  import Footer from './components/Footer.vue';
  import FAQ from './components/FAQ.vue';
  import Policy from './components/Policy.vue';
  import Cookies from './components/Cookies.vue';

  export default {
    name: 'App',
    components: {
      Header,
      Change,
      IsForYou,
      Freshness,
      Choose,
      FAQ,
      Footer,
      Policy,
      Cookies
    },
    methods: {
      changeViewInResize() {
        this.toggleViews();
      },
      checkScroll() {
        this.stickyHeaderFunc();
      },
      toggleViews() {
        this.$store.commit('toggleViews');
      },
      stickyHeaderFunc() {
        this.$store.commit('stickyHeaderFunc');
      }
    },
    beforeCreate: function() {},
    created: function() {
      window.addEventListener('resize', this.changeViewInResize);
      window.addEventListener('load', this.changeViewInResize);
      window.addEventListener('scroll', this.checkScroll);
      window.addEventListener('load', this.checkScroll);
    },
    destroyed: function() {
      window.removeEventListener('resize', this.changeViewInResize);
      window.removeEventListener('load', this.changeViewInResize);
      window.removeEventListener('scroll', this.checkScroll);
      window.removeEventListener('load', this.checkScroll);
    },
    computed: {
      // mobileView() {
      //   return this.$store.state.mobileView;
      // },
      // tabletView() {
      //   return this.$store.state.tabletView;
      // }
    }
  };
</script>

<style lang="sass">
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&family=Playfair+Display:wght@400;500&display=swap')
  @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital@0;1&display=swap')
  @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,900&display=swap')

  *
    margin: 0
    padding: 0
    box-sizing: border-box

  html
    scroll-behavior: smooth

  #App
    font-family: 'Open Sans', sans-serif
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    width: 100%
    height: 100%
    overflow-x: hidden
  .photo-text
    max-width: 1224px
    display: flex
  .container
    max-width: 1224px
  .main-button
    text-transform: uppercase
    border: none
    color: #ffffff
    border-radius: 20px
    font-weight: 700
    padding: 20px 36px
    cursor: pointer
    outline: none
    transition: 0.3s
    box-shadow: 5px 5px 10px 0px rgba(#000000, 0.16)
  h3
    color: #616161
    font-size: 42px
    font-family: 'Playfair Display', serif
    font-weight: 400
  a
    text-decoration: none
    color: #ffffff
    &:hover
      color: #eeeeee

  @media(max-width: 1050px)
    .main-button
      font-size: 14px
      padding: 20px 20px
      font-weight: 600
      line-height: 18px
      max-width: 90vw
</style>
