/* eslint-disable */
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

let cookieClickTime = JSON.parse(localStorage.getItem('altanaCookieClickTime'));
let cookieMessageVisible = false;
if (cookieClickTime + 120 * 24 * 60 * 60 * 1000 - Date.now() < 0) {
  cookieMessageVisible = true;
}

export const store = new Vuex.Store({
  state: {
    policyVisible: false,
    cookiesVisible: cookieMessageVisible,
    mobileMenuVisible: false,
    logoVisible: true,
    policyOpacity: '0',
    mobileView: true,
    tabletView: true,
    mobileFaq: true,
    stickyNav: false
  },
  mutations: {
    //change slide when click on the arrow
    changePolicyVisibility: state => {
      if (state.policyVisible === true) {
        state.policyOpacity = '0';
        setTimeout(() => {
          state.policyVisible = !state.policyVisible;
        }, 500);
      } else {
        state.policyVisible = !state.policyVisible;
        setTimeout(() => {
          state.policyOpacity = '1';
        }, 50);
      }
    },
    stickyHeaderFunc: state => {
      if (window.scrollY !== 0) {
        state.stickyNav = true;
      } else {
        state.stickyNav = false;
      }
    },
    toggleViews: state => {
      if (window.innerWidth > 900) {
        state.mobileView = false;
        state.tabletView = false;
      } else if (innerWidth > 600) {
        // if (innerWidth > 900) {
        state.mobileView = false;
        state.tabletView = true;
      } else {
        state.mobileView = true;
        state.tabletView = false;
      }
      if (window.innerWidth < 850) {
        state.mobileFaq = true;
      } else {
        state.mobileFaq = false;
      }
      // if (window.innerWidth < 900 && window.innerWidth > 600) {
      //   state.logoVisible = false;
      // } else {
      //   state.logoVisible = true;
      // }
    },
    showMenu: state => {
      state.mobileMenuVisible = !state.mobileMenuVisible;
    },
    escapeMenu: state => {
      state.mobileMenuVisible = false;
    },
    cookiesClick: state => {
      state.cookiesVisible = false;
      localStorage.setItem('altanaCookieClick', JSON.stringify(true));
      localStorage.setItem('altanaCookieClickTime', JSON.stringify(Date.now()));
    }
  }
});
