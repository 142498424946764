<template>
  <div v-if="cookiesVisible" id="Cookies">
    <p>
      Na naszej stronie używamy plików Cookie
    </p>
    <button v-on:click="cookiesClick">
      OK
    </button>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        //   cookiesVisibility: true
      };
    },
    computed: {
      // policyVisible() {
      //   return this.$store.state.policyVisible;
      // },
      // policyOpacity() {
      //   return this.$store.state.policyOpacity;
      // }
      cookiesVisible() {
        return this.$store.state.cookiesVisible;
      }
    },
    methods: {
      cookiesClick() {
        this.$store.commit('cookiesClick');
      }
    }
  };
</script>

<style lang="sass" scoped>
  #Cookies
    position: fixed
    bottom: 0
    width: 100vw
    padding: 10px 15px
    background: #d6d6d6
    color: #000
    display: flex
    justify-content: center
    align-items: center
    z-index: 5
    button
      width: 50px
      height: 20px
      background: #163a25
      color: #ffffff
      border: none
      outline: none
      cursor: pointer
      margin-left: 20px
      transition: background 0.1s
      &:hover
        background: #e6e6e6
        color: #163a25
    p
      max-width: 400px
      margin-right: 20px
</style>
