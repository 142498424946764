<template>
  <div id="Header">
    <Navigation />
    <div class="photo-text" id="header-content">
      <div id="header-text">
        <h2>Stwórz</h2>
        <h1>Raj dla kwiatów</h1>
        <p v-if="!mobileView">
          Skupiasz się na tym, by dostarczać piękno najwyższej jakości? Altana
          chłodnicza pomoże Ci ograniczyć straty, zachowa świeżość kwiatów na
          dłużej i pomoże wyeksponować florystyczne dzieła sztuki.
        </p>
        <p>
          Altana to raj dla roślin i idealne rozwiązanie dla profesjonalistów.
          Tworzy najlepszy dla kwiatów klimat, by oszczędzić Twoje pieniądze.
        </p>
        <div id="button-container">
          <a href="#Choose">
            <button id="header-button" class="main-button">
              wybierz altanę dla siebie
            </button>
          </a>
        </div>
      </div>
      <div id="header-photo">
        <img
          src="../assets/header-altana.png"
          alt="czarna altana kwiatowa Rapa"
        />
      </div>
    </div>
  </div>
</template>

<script>
  /* eslint-disable */
  import Navigation from './Navigation.vue';

  export default {
    name: 'App',
    components: {
      Navigation
    },
    computed: {
      mobileView() {
        return this.$store.state.mobileView;
      }
    }
  };
</script>

<style lang="sass" scoped>
  #Header
    width: 100vw
    height: 800px
    background-image: url("../assets/gradientBack.png"), url("../assets/wyposazenie_profesjonalnej_kwiaciarni.png")
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    color: white
    #header-content
      margin: auto
      display: flex
      #header-text
        width: 50%
        padding-top: 110px
        h1
          font-family: 'Playfair Display', serif
          font-size: 72px
          letter-spacing: 1px
        h2
          font-family: 'Playfair Display', serif
          font-size: 60px
          font-weight: 400
          margin-bottom: -15px
        p
          margin-top: 25px
          font-weight: 400
          line-height: 24px
          letter-spacing: -0px
        #button-container
          #header-button
            margin-top: 45px
            background-color: #AE2A36
            transition: 0.3s
            border: 1px solid #AE2A36
            position: relative
            top: 0px
            left: 0px
            &:hover
              color: #AE2A36
              background-color: white
      #header-photo
        transform: translateX(70px) translateY(50px)
        img
          width: 650px
          height: auto
  @media(max-width: 1050px)
    #Header
      height: 740px
      background-image: url("../assets/gradientBack.png"), url("../assets/wyposazenie_kwiaciarni.png")
      background-size: 300%
      background-position: 35% 30%
      #header-content
        width: 90%
        #header-text
          padding-top: 90px
          position: relative
          h1
            width: 50vw
            font-size: 47px
            margin-bottom: 45px
          h2
            font-size: 39px
          #button-container
            position: absolute
            width: 90vw
            display: flex
            justify-content: center
            #header-button
              margin-top: 120px
        #header-photo
          transform: translateX(-30px) translateY(60px)
          img
            width: 465px
  @media(max-width: 850px)
    #Header
      background-position: 35% 40%
      #header-content
        #header-text
          #button-container
            #header-button
              margin-top: 60px
  @media(max-width: 700px)
    #Header
      height: auto
      background-size: 500%
      background-position: 42% 38%
      #header-content
        flex-direction: column-reverse
        #header-text
          width: 100%
          padding: 0px 40px 10px
          h1
            font-size: 40px
            font-weight: 500
            margin: 10px 0px
          h2
            font-size: 32px
            font-weight: 300
          p
            font-size: 16px
            font-weight: 300
            line-height: 22px
            letter-spacing: 1px
            padding: 20px 0px 0
          #button-container
            width: 100%
            position: relative
            #header-button
              margin: 45px auto
              &:hover
                color: white
                background-color: #AE2A36
        #header-photo
          padding-top: 20px
          transform: translateX(0px) translateY(0px)
          width: 100vw
          display: flex
          justify-content: center
          img
            width: 87vw
  @media(max-width: 500px)
    #Header
      background-size: 550%
      background-position: 41% 45%
      #header-content
        #header-text
          h1
            font-size: 36px
          h2
            font-size: 28px
          p
            font-size: 15px
          #button-container
            #header-button
              font-size: 12px
</style>
