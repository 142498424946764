<template>
  <div id="Footer">
    <div class="container" id="footer-container">
      <div id="rapa-info">
        <h5>RAPA</h5>
        <div>S. Międlar, W. i I. Szymańscy sp. j.</div>
        <div>ul. Ceramiczna 9, 20-149 Lublin</div>
        <div>NIP: 712-19-31-385</div>
        <div>BDO: 000009031</div>
        <div>Regon: 430833425</div>
        <div
          v-if="!mobileView"
          v-on:click="changePolicyVisibility()"
          class="polityka"
        >
          Polityka Prywatności
        </div>
      </div>
      <div id="contact">
        <div>tel. +48 81 742 53 10-14</div>
        <div id="mail">
          <a href="mailto:rapa@rapa.lublin.pl">rapa@rapa.lublin.pl</a>
        </div>
        <div id="site">
          <a href="https://www.rapa.pl" target="_blank">rapa.pl</a>
        </div>
        <div>Zapraszamy od poniedziałku do piątku</div>
        <div>w godz: 7:45 - 15:45</div>
        <div
          v-if="mobileView"
          v-on:click="changePolicyVisibility()"
          id="polityka2"
        >
          Polityka Prywatności
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
  export default {
    computed: {
      policyVisible() {
        return this.$store.state.policyVisible;
      },
      mobileView() {
        return this.$store.state.mobileView;
      }
    },
    methods: {
      changePolicyVisibility() {
        this.$store.commit('changePolicyVisibility');
      }
    }
  };
</script>

<style lang="sass" scoped>
  #Footer
    height: 346px
    background-image: url('../assets/kontakt_rapa.png')
    color: #fff
    padding-top: 65px
    padding-bottom: 45px
    #footer-container
      margin: auto
      display: flex
      justify-content: space-between
      #rapa-info
        h5
          font-family: 'Playfair Display', serif
          font-size: 30px
          font-weight: 300
        div
          font-weight: 400
        .polityka
          margin-top: 65px
          cursor: pointer
      #contact
        font-weight: 400
        text-align: right
        #site
          margin: 25px 0
          cursor: pointer
        #mail
          cursor: pointer
  @media(max-width: 1050px)
    #Footer
      background-image: url('../assets/kontakt_rapa_tablet.png')
      #footer-container
        width: 90vw
  @media(max-width: 700px)
    #Footer
      height: auto
      background-image: url('../assets/kontakt_rapa_mobile.jpg')
      background-position: 8% 70%
      background-size: 300%
      #footer-container
        height: auto
        flex-direction: column
        justify-content: flex-start
        #rapa-info
          text-align: center
          h5
            font-size: 24px
          div
            font-weight: 300
        #contact
          font-weight: 300
          margin-top: 75px
          text-align: center
          font-size: 15px
          #polityka2
            margin: 60px 0
</style>
