<template>
  <div
    v-on:click="policyBackgroundClick"
    v-bind:style="{ opacity: policyOpacity }"
    v-if="policyVisible"
    id="Policy"
  >
    <div class="container" id="main-policy-container">
      <div class="container" id="policy-container">
        <div v-on:click="changePolicyVisibility" id="exit">x</div>
        <div id="text">
          <h3>Informacje dotyczące ochrony danych osobowych</h3>
          <p>
            Ochrona prywatności przy przetwarzaniu Państwa danych osobowych to
            istotne zagadnienie, na które zwracamy szczególną uwagę w naszej
            działalności biznesowej. Dane osobowe pobrane podczas Państwa wizyty
            na naszej stronie internetowej traktujemy jako poufne i przetwarzamy
            zgodnie z przepisami prawa. Ochrona danych osobowych i
            bezpieczeństwo informacji są częścią polityki naszej firmy.
          </p>
          <h3 id="policy-data">
            Informacje oochronie oraz sposobie przetwarzania danych osobowych
            przez <br />
            RAPA S. Międlar W. i I. Szymańscy sp. j., ul. Ceramiczna 9, <br />
            20-149 Lublin, NIP 7121931385
          </h3>
          <p>
            Od dnia 25 maja 2018 roku, jako Administrator Państwa danych
            osobowych, odpowiadamy za ich wykorzystywanie zgodnie z
            Rozporządzeniem Parlamentu Europejskiego i Rady UE 2016/679 z dnia
            27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z
            przetwarzaniem danych osobowych i w sprawie swobodnego przepływu
            takich danych oraz uchylenia dyrektywy 95/46/WE (Rozporządzenie o
            ochronie danych osobowych), którego celem jest ujednolicenie zasad
            przetwarzania danych osobowych na terenie UE.
          </p>
          <h4>I. Cel oraz podstawa wykorzystywania danych osobowych:</h4>
          <ul>
            <li>
              przetwarzanie Państwa danych osobowych do wykonania zawartej
              między nami umowy lub wykonania naszych prawnie uzasadnionych
              interesów,
            </li>
            <li>
              korzystanie z udostępnianych przez Państwa zasobów wirtualnych (w
              tym mailing informacyjny, dostęp do platform, oraz aplikacji
              wymagających jednoznacznej weryfikacji użytkownika),
            </li>
            <li>
              weryfikacja zdolności finansowej w kontekście płatności za towary
              lub usługi,
            </li>
            <li>
              przeprowadzanie procesu handlowego, a zwłaszcza komunikacji,
            </li>
            <li>realizacja transportu zamawianych towarów i usług,</li>
            <li>
              rozpatrywanie oraz obsługa składanych przez Państwa reklamacji
              dotyczących towarów i usług,
            </li>
            <li>obsługa zapytań i zgłoszeń, w tym serwisowych,</li>
            <li>
              obsługa i prowadzenie ewidencji certyfikatów urządzeń będących w
              okresie gwarancyjnym,
            </li>
            <li>
              przetwarzanie Państwa danych dla celów podatkowych i rachunkowych,
            </li>
            <li>prawnie uzasadniony interes firmy,</li>
            <li>
              analizowanie i zarządzanie Państwa aktywnością na stronach
              internetowych należących do firmy RAPA S. Międlar W. i I.
              Szymańscy sp. j. celem dostosowania treści do Państwa
              indywidualnych preferencji, zainteresowań oraz zachowań,
            </li>
            <li>organizacja programów lojalnościowych i konkursów,</li>
            <li>
              prowadzenie działań promujących ofertę własną towarów i usług,
            </li>
            <li>windykacja należności,</li>
            <li>
              zapewnienie bezpieczeństwa i integralności usług świadczonych
              drogą elektroniczną,
            </li>
            <li>
              kontaktowanie się celach związanych z dozwolonymi działaniami
              marketingowymi,
            </li>
            <li>prowadzenie analiz statystycznych,</li>
            <li>
              przechowywanie danych dla celów archiwalnych oraz zapewnienia
              rozliczalności (wykazanie spełnienia przez nas obowiązków
              wynikających z przepisów prawa).
            </li>
          </ul>
          <h4>II. Rodzaj danych osobowych:</h4>
          <ul>
            <li>pełna nazwa firmy lub imię i nazwisko,</li>
            <li>adres rejestracyjny lub zameldowania,</li>
            <li>adres do korespondencji,</li>
            <li>nr NIP,</li>
            <li>adres poczty e-mail,</li>
            <li>numer telefonu.</li>
          </ul>
          <p>
            Jeśli nie zostaną podane powyżej wskazane dane, nie będzie możliwym
            zawarcie umowy między stronami. Podanie przez Państwa pozostałych
            danych osobowych jest dobrowolne.
          </p>
          <h4>III. Przekazywanie danych</h4>
          <p>
            RAPA S. Międlar W. i I. Szymańscy sp. j. a w ramach prowadzonej
            działalności przekazuje dane osobowe następującym podmiotom:
          </p>
          <ul>
            <li>
              pracownikom oraz współpracownikom, a także świadczącym nam usługi
              doradcze i konsultacyjne,
            </li>
            <li>
              świadczącym usługi zarządzania systemem teleinformatycznym,
              świadczącym usługi kurierskie oraz pocztowe (w celu prowadzenia
              niezbędnej korespondencji w powierzonych nam sprawach).
            </li>
          </ul>
          <h4>IV. Prawo dostępu do danych</h4>
          <ul>
            <li>
              Przepisy Rozporządzenia o ochronie danych osobowych uprawniają
              Państwa do wystąpienia do nas z żądaniem:
            </li>
            <li>udzielenia informacji o przetwarzanych danych,</li>
            <li>wydania kopii przetwarzanych danych,</li>
            <li>niezwłocznego sprostowania nieprawidłowych danych,</li>
            <li>
              uzupełnienia niekompletnych danych osobowych, w tym poprzez
              przedstawienie dodatkowego oświadczenia,
            </li>
            <li>
              ograniczenia przetwarzania danych w przypadku zakwestionowania ich
              prawidłowości,
            </li>
            <li>
              niezwłocznego usunięcia danych bezpodstawnie przetwarzanych,
            </li>
            <li>
              przeniesienia danych do innego administratora w powszechnie
              używanym formacie, nadającym się do odczytu maszynowego.
            </li>
          </ul>
          <p>
            W każdej chwili możecie Państwo wycofać swoją zgodę na przetwarzanie
            danych osobowych telefonicznie lub drogą mailową: +48 817184608,
            gdpr@rapa.lublin.pl.
          </p>
          <h4>V. Prawo do sprzeciwu</h4>
          <p>
            Wobec przetwarzania danych osobowych niezbędnych do wykonania przez
            nas prawnie uzasadnionych interesów – mogą Państwo wnieść sprzeciw w
            sytuacjach szczególnych. W tym przypadku nie wolno nam będzie
            przetwarzać tych danych osobowych, chyba, że wykażemy istnienie
            ważnych, prawnie uzasadnionych podstaw do przetwarzania, nadrzędnych
            wobec interesów, praw i wolności osoby, której dane dotyczą, lub
            podstaw do ustalenia, dochodzenia lub obrony roszczeń. Natomiast,
            jeżeli Państwa dane osobowe są przetwarzane na potrzeby marketingu
            bezpośredniego, mają Państwo prawo w dowolnym momencie wnieść
            sprzeciw wobec ich przetwarzania wywołujący skutek natychmiastowy.
          </p>
          <h4>VI. Prawo do wniesienia skargi</h4>
          <p>
            Jeżeli uznają Państwo, iż dokonywane przez RAPA S. Międlar W. i I.
            Szymańscy sp. j. przetwarzanie danych osobowych jest niezgodne z
            prawem -przysługuje Państwu skarga do Prezesa Urzędu Ochrony Danych
            Osobowych.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  /* eslint-disable */
  export default {
    computed: {
      policyVisible() {
        return this.$store.state.policyVisible;
      },
      policyOpacity() {
        return this.$store.state.policyOpacity;
      }
    },
    methods: {
      changePolicyVisibility() {
        this.$store.commit('changePolicyVisibility');
      },
      escapePolicy(e) {
        if (e.keyCode === 27 && this.policyVisible === true) {
          this.changePolicyVisibility();
        }
      },
      policyBackgroundClick(e) {
        if (e.target.id === 'Policy') {
          this.changePolicyVisibility();
        }
      }
    },
    created() {
      window.addEventListener('keyup', this.escapePolicy);
    },
    destroyed() {
      window.removeEventListener('keyup', this.escapePolicy);
    }
  };
</script>

<style lang="sass" scoped>
  #Policy
    position: fixed
    width: 100vw
    height: 100vh
    background-color: rgba(#000000, 0.8)
    display: flex
    justify-content: center
    align-items: center
    z-index: 200
    transition: 0.3s
    #main-policy-container
      height: 850px
      width: 1224px
      display: flex
      align-items: center
      background-color: #ffffff
      border-radius: 20px
      #policy-container
        width: 1224px
        height: 800px
        overflow-y: scroll
        padding: 45px
        position: relative
        h3
          font-size: 20px
          font-family: 'Open Sans', sans-serif
          font-weight: 700
          color: #000000
          margin: 20px 0
        #policy-data
          text-align: center
        h4
          margin-top: 20px
        ul
          padding-left: 45px
        #exit
          position: absolute
          top: 0
          right: 20px
          font-size: 40px
          cursor: pointer
  @media(max-width: 1050px)
    #Policy
      font-size: 12px
      #main-policy-container
        height: 90vh
        width: 90vw
        align-items: flex-start
        #policy-container
          height: 100%
          h3
            font-size: 16px
          #exit
            top: 20px
</style>
